/*
 * @Author: yangzonglong
 * @Date: 2021-03-31 11:33:40
 * @version: v1.0.0
 * @Descripttion: store
 * @LastEditors: yangzonglong
 * @LastEditTime: 2021-06-01 14:58:46
 * @Auditor: 
 */
import { createStore } from 'redux';
import { combineReducers } from 'redux';
import commonReducer from './commonReducer';

const reducers = combineReducers({
  common: commonReducer
})

const store = createStore(reducers);

export default store;